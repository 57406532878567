import { BLUR_DISPLAY, BLUR_CONTENT } from './types';

export const toggleDisplayBlur = (display, content) => (dispatch) => {
  let pageContent = document.getElementById('pageContent');
  if (pageContent) {
    if (!display === true) {
      // pageContent.style.filter = 'blur(2rem)';
      pageContent.classList.add('isBlur');
    } else {
      //pageContent.style.filter = '';
      pageContent.classList.remove('isBlur');
    }
  }
  let canPlay = !display;
  if (canPlay === false) {
    setTimeout(() => {
      document.querySelectorAll('video').forEach((vid) => vid.play());
    }, 500);
  }
  dispatch({
    type: BLUR_DISPLAY,
    payload: !display,
  });
  dispatch(blurContent(content));
};

export const blurContent = (component) => (dispatch) => {
  dispatch({
    type: BLUR_CONTENT,
    payload: component,
  });
};
