import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  ulStyle,
  liStyle,
  buttonStyle,
  buttonStyleActive,
  buttonStyleActiveGreen,
} from './SlidetStyle';

const Slider = ({ page, colorWhite }) => {
  const [interval, setInterval] = useState();
  const [data, setData] = useState([]);
  const [activeItem, setActiveItem] = useState(0);
  //const color = colorWhite? '#f2f2f2' : '#084A59';
  const buttonStyleA = colorWhite ? buttonStyleActive : buttonStyleActiveGreen;
  const setItem = (index) => {
    clearTimeout(interval);
    setActiveItem(index);
  };
  useEffect(() => {
    (async () => {
      let response = await axios.get(
        'https://gplusproduction.pl/services/cms_object_data.php?page=' +
          page +
          '&component=slider'
      );
      setData(response.data);
    })();
  }, []);
  useEffect(() => {
    let interval = setTimeout(() => {
      setActiveItem(activeItem === data.length - 1 ? 0 : activeItem + 1);
    }, 3000);
    setInterval(interval);
  }, [activeItem]);
  return (
    <>
      {data.length > 0 ? (
        <>
          <div>
            <div className="about__header">{data[activeItem].title}</div>
            <div className="about__small">{data[activeItem].description}</div>
          </div>

          <div>
            <ul style={ulStyle}>
              <li style={liStyle}>
                <button
                  onClick={() => setItem(0)}
                  style={activeItem === 0 ? buttonStyleA : buttonStyle}
                ></button>
              </li>
              <li style={liStyle}>
                <button
                  onClick={() => setItem(1)}
                  style={activeItem === 1 ? buttonStyleA : buttonStyle}
                ></button>
              </li>
              {/* <li style={liStyle}>
                <button
                  onClick={() => setItem(2)}
                  style={activeItem === 2 ? buttonStyleA : buttonStyle}
                ></button>
              </li> */}
            </ul>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Slider;
