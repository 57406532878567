import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="container-fluid" style={{ position: 'relative' }}>
      <div
        className="row pt-3"
        style={{
          color: '#f2f2f2',
          width: '100%',
          position: 'absolute',
          bottom: '24px',
        }}
      >
        <div
          className="col-md-7"
          style={{ textAlign: 'left', padding: 0, margin: 0 }}
        >
          <div className=" d-flex" style={{ paddingBottom: '.5rem' }}>
            <div className="footer-item pl-xs-0">
              <Link
                style={{ textDecoration: 'none', color: '#fff' }}
                to="/"
                onClick={() => window.scrollTo(0, 0)}
              >
                Home
              </Link>
            </div>
            <div className="footer-item">
              <Link
                style={{ textDecoration: 'none', color: '#fff' }}
                to="/onas"
                onClick={() => window.scrollTo(0, 0)}
              >
                O nas
              </Link>
            </div>
            <div className="footer-item">
              <Link
                style={{ textDecoration: 'none', color: '#fff' }}
                to="/casting"
                onClick={() => window.scrollTo(0, 0)}
              >
                Casting
              </Link>
            </div>
            <div className="footer-item d-none d-sm-none d-md-block">
              <Link
                style={{ textDecoration: 'none', color: '#fff' }}
                to="/regulamin"
                onClick={() => window.scrollTo(0, 0)}
              >
                Regulamin i Polityka prywatności
              </Link>
            </div>
          </div>
          <div
            className="footer-item d-block d-sm-block d-md-none pb-3 text-left"
            style={{ padding: 0 }}
          >
            <Link
              style={{ textDecoration: 'none', color: '#fff' }}
              to="/regulamin"
              onClick={() => window.scrollTo(0, 0)}
            >
              Regulamin i Polityka prywatności
            </Link>
          </div>
        </div>
        <div
          className="col-md-5"
          style={{
            minHeight: '16px',
            fontSize: '14px',
            color: '#BDB9B9',
            paddingLeft: 0,
          }}
        >
          <div className="d-block d-sm-block d-md-none">
            | Copyright GPlus Production 2020
          </div>
          <div className="d-none d-md-block text-right">
            Copyright GPlus Production 2020
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
