import React from 'react';
import { Link } from 'react-router-dom';
import HeaderIcons from '../../components/Headers/HeaderIcons';

const CastingHeader = () => {
  return (
    <header className="header" style={{ height: '100vh' }}>
      <div
        className="header__overlay"
        style={{
          backgroundImage:
            'url(' + require('../../assets/img/justcasttop.png') + ')',
          width: '100vw',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      ></div>
      <div className="container h-100 casting__container">
        <div className="d-flex h-100 text-center align-items-center">
          <div
            className="w-100 h-100 text-center header__subject"
            style={{ display: 'block' }}
          >
            <div className="h-50">
              <div style={{ textAlign: 'left', paddingTop: '2.5rem' }}>
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  <img
                    src={require('../../assets/img/logo.svg')}
                    alt="logo"
                    style={{ position: 'fixed' }}
                  />
                </Link>
              </div>
            </div>
            <div className="h-50">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  height: '100%',
                  textAlign: 'left',
                  paddingBottom: '3rem',
                }}
              >
                <div className="flex-fill header__subject-item">CASTING</div>
              </div>
            </div>
          </div>
          <div
            className="w-100 text-white text-center"
            style={{ position: 'absolute' }}
          >
            <img
              src={require('../../assets/img/just-cast-circle.svg')}
              className="pointer"
              alt="just-cast-circle"
              style={{ width: '250px' }}
            />
          </div>
          <HeaderIcons type={2} />
        </div>
      </div>
    </header>
  );
};

export default CastingHeader;
