import React from 'react';
import Contact from '../Contact/Contact';
import RulesItem from './RulesItem';
import { connect } from 'react-redux';
import { toggleDisplayBlur } from '../../actions/blur';

const rulesData = [
  {
    title: 'Cookie policy',
    description:
      'This website is operated by Brother Film Co LLP (“Brother Film Co”). Our registered office is Kempston, Mill Hill, Edenbridge, Kent, TN8 5DQ. You can contact us by writing to us at Brother Film Co, Unit 307 Peckham Levels, Peckham Town Centre Car Park, 95A Rye Lane, Peckham, SE15 4ST.',
    description1:
      'Throughout our Website we may include links to third-party websites, plug-ins and applications. These other third party websites may also use cookies or similar technologies in accordance with their own separate cookie polices. For privacy information relating to these other third party websites, please consult their cookie policies as appropriate.',
  },
  {
    title: 'What are cookies?',
    description:
      'A cookie is a small text file which is placed onto your device (e.g. computer, smartphone or other electronic device) when you use our Website. A cookie file is stored in your web browser and allows us or a third-party to recognise you and remember things like your personalised details or user preferences. They also help us learn more about how you use and access our Website so that we can make improvements and update features.',
    description1: '',
  },
  {
    title: 'Cookie policy',
    description:
      'This website is operated by Brother Film Co LLP (“Brother Film Co”). Our registered office is Kempston, Mill Hill, Edenbridge, Kent, TN8 5DQ. You can contact us by writing to us at Brother Film Co, Unit 307 Peckham Levels, Peckham Town Centre Car Park, 95A Rye Lane, Peckham, SE15 4ST.',
    description1: '',
  },
  {
    title: 'What are cookies?',
    description:
      'A cookie is a small text file which is placed onto your device (e.g. computer, smartphone or other electronic device) when you use our Website. A cookie file is stored in your web browser and allows us or a third-party to recognise you and remember things like your personalised details or user preferences. They also help us learn more about how you use and access our Website so that we can make improvements and update features.',
    description1:
      'Cookies can be “persistent” or “session” cookies. Persistent cookies remain on your device when you go offline, while session cookies are deleted as soon as you close your web browser.',
  },
];

const Rules = ({ displayBlur, toggleDisplayBlur }) => {
  return (
    <>
      <div
        className="container"
        style={{ color: '#084A59', paddingBottom: '5rem' }}
      >
        <div className="row">
          <div className="col-6"></div>
          <div
            className="col-6"
            style={{ textAlign: 'right', paddingTop: '3rem' }}
          >
            <img
              className="img-pus"
              src={require('../../assets/img/plus.svg')}
              alt="plus"
              onClick={() => toggleDisplayBlur(displayBlur, 'menu')}
            />
          </div>
          <div
            className="col-12"
            style={{
              textAlign: 'center',
              fontSize: '80px',
              paddingBottom: '70px',
            }}
          >
            REGULAMIN
          </div>
        </div>
        {rulesData.map((rule, index) => (
          <RulesItem
            key={index}
            title={rule.title}
            description={rule.description}
            description1={rule.descriptio1}
          />
        ))}
      </div>
      <Contact />
    </>
  );
};
const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(Rules);
