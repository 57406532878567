import React, { useEffect, useState } from "react";
import axios from "axios";
// import FooterContact from '../Footers/FooterContact';
import { connect } from "react-redux";
import { toggleDisplayBlur } from "../../actions/blur";

const carouselRorate = () => {
  var itemPreview = -1;
  let items = document.getElementsByClassName("carousel-item");
  Array.from(items).forEach(function (el, index) {
    if (el.classList.contains("active")) {
      el.classList.remove("active");
      el.classList.add("last");
      setTimeout(() => {
        el.classList.remove("last");
      }, 500);
    }
    if (el.classList.contains("preview")) {
      el.classList.remove("preview");
      el.classList.add("active");
      if (index + 1 >= items.length) {
        itemPreview = 0;
      } else {
        itemPreview = index + 1;
      }
    }
  });
  items[itemPreview].classList.add("preview");
};

const Carousel = ({ page, displayBlur, toggleDisplayBlur }) => {
  const [images, setImages] = useState(null);
  useEffect(() => {
    (async () => {
      let response = await axios.get(
        "https://gplusproduction.pl/services/cms_object_data.php?page=" +
          page +
          "&component=carousel"
      );
      setImages(response.data);
    })();
  }, []);

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-ride="carousel"
      style={{ width: "100vw" }}
    >
      <div
        className="carousel-inner"
        style={{ display: "flex", height: "100vh" }}
        onClick={() => carouselRorate()}
      >
        {images === null
          ? ""
          : images.map((image, index) => (
              <div
                key={image.id}
                className={
                  index === 0
                    ? "carousel-item active slide-left"
                    : index === 1
                    ? "carousel-item preview slide-left"
                    : "carousel-item slide-left"
                }
              >
                <img
                  className="d-block w-100"
                  src={require(`../../assets/img/gallery/${image.src}`)}
                  alt="img"
                  style={{ height: "100%", objectFit: "cover" }}
                />
              </div>
            ))}
      </div>
      <a
        className="carousel-control-next d-flex d-sm-none"
        role="button"
        data-slide="next"
        style={{ justifyContent: "left", width: "26%", zIndex: "99" }}
      >
        <img
          src={require("../../assets/img/next.svg")}
          style={{ marginTop: "-2px", cursor: "pointer" }}
          alt="next"
          onClick={() => carouselRorate()}
        />
      </a>
    </div>
  );
};
const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(Carousel);
