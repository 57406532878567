import React from 'react';
import Accordion from '../../components/Accordion/Accordion';

const WhatWeDo = () => {
  return (
    <>
      <div className="row" style={{ alignItems: 'center' }}>
        <div className="col-md-6">
          <div className="about__header" style={{ color: '#084A59' }}>
            CO
            <br />
            ROBIMY
          </div>
        </div>
        <div className="col-md-6">
          <Accordion page="onas" />
        </div>
      </div>
    </>
  );
};

export default WhatWeDo;
