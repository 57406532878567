import React from 'react';
// import FooterContact from '../../components/Footers/FooterContact';
// import HeaderIcons from '../../components/Headers/HeaderIcons';

const Info = () => {
  return (
    <section
      className="bg-green"
      style={{
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div className="container-fluid" style={{ paddingTop: '5rem' }}>
        <div className="row" style={{ paddingBottom: '5rem' }}>
          <div
            className="w-100"
            style={{ textAlign: 'left', display: 'flex', flexWrap: 'wrap' }}
          >
            <div className="just-cast-info">
              <div style={{ zIndex: 2 }} className="section-title">
                JUST CAST
              </div>
              <div
                style={{
                  marginTop: '-2.75rem',
                  marginLeft: '-1.2rem',
                  zIndex: 1,
                }}
              >
                <img
                  className="img-pus"
                  src={require('../../assets/img/just-cast-circle.svg')}
                  alt="justcast"
                />
              </div>
            </div>
            <div
              style={{
                fontSize: '22px',
                letterSpacing: 0,
                lineHeight: '34px',
                width: '100%',
              }}
            >
              Justcast to zbudowana na bazie wieloletnich doświadczeń usługa
              castingowa.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 pb-5">
            <div
              style={{ color: '#89CAD9', fontSize: '20px', lineHeight: '24px' }}
            >
              Mamy
            </div>
            <div style={{ marginTop: '20px' }} className="mw-item">
              Bazę z interesującymi postaciami, aktorami, statystami, własne
              studio castingowe, stałą i doświadoczoną ekipę.
            </div>
          </div>
          <div className="col-md-4 pb-5">
            <div
              style={{ color: '#89CAD9', fontSize: '20px', lineHeight: '24px' }}
            >
              Oferujemy
            </div>
            <div style={{ marginTop: '20px' }} className="mw-item">
              Wyszukiwanie najlepszych kandydatów do twojej produkcji, pełną
              obsługę produkcji na planie, casting online, indywidualny dostęp
              do bazy.
            </div>
          </div>
          <div className="col-md-4 pb-5">
            <div
              style={{ color: '#89CAD9', fontSize: '20px', lineHeight: '24px' }}
            >
              Interesują nas
            </div>
            <div style={{ marginTop: '20px' }} className="mw-item">
              Projekty filmowe, eventy marketingowe, kampanie reklamowe,
              Projekty filmowe, eventy firmowe i marketingowe, kampanie
              reklamowe.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
