import React, { useState, useEffect } from 'react';
import AccordionItem from './AccordionItem';
import axios from 'axios';

const Accordion = ({ page }) => {
  const [active, setActive] = useState(null);
  const [list, setList] = useState([]);
  useEffect(() => {
    (async () => {
      let response = await axios.get(
        'https://gplusproduction.pl/services/cms_object_data.php?page=' +
          page +
          '&component=accordion'
      );
      setList(response.data);
    })();
  }, []);

  return (
    <>
      <div>
        {list.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.title}
            description={item.description}
            index={index}
            active={active === index}
            setActive={setActive}
          />
        ))}
      </div>
    </>
  );
};

export default Accordion;
