import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import HeaderIcons from '../../components/Headers/HeaderIcons';

const previewURL = require('../../assets/video/SR_10_sek.mp4');
const fullMovieURL = require('../../assets/video/SR_V4.mp4');

const Movie = () => {
  const [movieState, setMovieState] = useState(false);
  const [videoURL, setVideoURL] = useState(previewURL);
  useEffect(() => {
    setVideoURL(videoURL);
    if (movieState) {
      let el = document.getElementById('headerFILM');
      el.pause();
      el.setAttribute('src', fullMovieURL);
      el.load();
      el.play();
    }
  }, [movieState]);
  return (
    <div className="header" style={{ height: '100vh' }}>
      <div className="header__overlay"></div>
      <video
        id="headerFILM"
        className="header__video-100vw"
        playsInline="playsInline"
        autoPlay="autoPlay"
        loop="loop"
        style={{ width: '100vw', maxWidth: '100vw' }}
      >
        <source src={videoURL} type="video/mp4" />
      </video>
      <div
        className="container h-100"
        style={{ width: '100%', maxWidth: '100%' }}
      >
        <div
          className="d-flex h-100 text-center align-items-center"
          style={{ justifyContent: 'center' }}
        >
          {/* 
            <div className="w-100 h-100 text-center header__subject" style={{alignItems: 'flex-start',paddingTop:"2.5rem"}}>
                <Link to="/" onClick={()=>window.scrollTo(0,0)}>
                    <img src={require('../../assets/img/logo.svg')} alt="logo" />
                </Link> 
            </div>
            */}
          <div
            className="text-white text-center"
            style={{ position: 'absolute' }}
          >
            {movieState ? (
              ''
            ) : (
              <img
                onClick={() => setMovieState(true)}
                src={require('../../assets/img/play.svg')}
                alt="play"
                className="pointer"
              />
            )}
          </div>
          {/* <HeaderIcons type={2} /> */}
        </div>
      </div>
    </div>
  );
};

export default Movie;
