import React from "react";
import HeaderImage from "../../components/Headers/HeaderImage";
import AboutUS from "../../components/AbouUs";
import Carousel from "../../components/Carousel/Carousel";
import Contact from "../../components/Contact/Contact";
import Movie from "./Movie";
import Info from "./Info";
import WhatWeDo from "./WhatWeDo";

const images = ["", "", ""];

const About = () => {
  return (
    <>
      <HeaderImage />
      <AboutUS showSlider={true} scroll={true} />
      <section
        className="bg-white"
        style={{
          width: "100vw",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="container-fluid">
          <WhatWeDo />
        </div>
      </section>
      <Info />
      <Carousel page="onas" images={images} />
      <Contact />
    </>
  );
};

export default About;
