import React from 'react';

const RulesItem = ({ title, description, description1 }) => {
  return (
    <div className="row" style={{ paddingTop: '60px' }}>
      <div className="col-12" style={{ fontSize: '18px', fontWeight: 'bold' }}>
        {title}
      </div>
      <div className="col-12" style={{ paddingTop: '15px' }}>
        {description}
      </div>
    </div>
  );
};

export default RulesItem;
