import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';

const AccordionItem = ({ title, description, setActive, active, index }) => {
  return (
    <div
      onClick={() => setActive(active ? null : index)}
      className="row"
      style={{ borderBottom: '1px solid #084A59', margin: 0 }}
    >
      <div
        className="col-10"
        style={{
          margin: 0,
          padding: 0,
          fontWeight: 'bold',
          paddingBottom: '.75rem',
          paddingTop: '1rem',
          cursor: 'pointer',
        }}
      >
        {title}
      </div>
      <div
        className="col-2"
        style={{
          textAlign: 'right',
          margin: 0,
          padding: 0,
          paddingBottom: '.75rem',
          paddingTop: '1rem',
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon icon={active ? faAngleUp : faAngleDown} />
      </div>
      <div
        className="col-12"
        style={{
          display: active ? 'block' : 'none',
          margin: 0,
          padding: 0,
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        {ReactHtmlParser(description)}
      </div>
    </div>
  );
};

export default AccordionItem;
