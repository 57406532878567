import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Routes from './Routes';

// Redux
import { Provider } from 'react-redux';
import store from './store';

import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import BlurPage from './components/BlurPage';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <BlurPage />
        <div id="pageContent">
          <Switch>
            <Route component={Routes} />
          </Switch>
        </div>
      </BrowserRouter>
    </Provider>
  );
};
export default App;
