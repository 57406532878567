import React from 'react';

const FooterFacebook = () => {
  return (
    <div className="d-none d-md-flex w-100 pt-5">
      <div className="row w-100">
        <div className="col-4 offset-4 text-center gplusfacebook">
          <div className="d-flex justify-content-center w-100">
            <div style={{ borderBottom: '3px solid', paddingBottom: '3px' }}>
              <a
                href="https://www.facebook.com/GPlusProduction/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#084A59', textDecoration: 'none' }}
              >
                #gpfacebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterFacebook;
