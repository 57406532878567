import React from 'react';
import Home from './Home/Home';

const Index = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default Index;
