import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toggleDisplayBlur } from '../actions/blur';
import Menu from './Menu/Menu';
import ProjectsForm from '../views/ProjectsForm/ProjectsForm';
import ProjectsFormSuccess from '../views/ProjectsForm/ProjectsFormSuccess';

const BlurPage = ({ displayBlur, content, toggleDisplayBlur }) => {
  //window.scrollTo(0,0);
  const [rotate, setRotate] = useState(false);

  useEffect(() => {
    if (displayBlur) {
      setTimeout(() => {
        setRotate(true);
      }, 500);
    }
  }, [displayBlur]);

  const setDisplay = () => {
    setRotate(false);
    toggleDisplayBlur(displayBlur);
  };

  document.querySelectorAll('video').forEach((vid) => vid.pause());

  if (!displayBlur) return '';

  return (
    <div className="blurPage" style={{ overflowY: 'auto' }}>
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <div className="blurPage__top">
          <img
            className="img-close"
            src={require('../assets/img/plus.svg')}
            alt="plus"
            style={{ transform: `rotate(${rotate ? '45deg' : '0deg'})` }}
            onClick={() => setDisplay()}
          />
        </div>
        {/* <div style={{position: 'absolute',bottom: '5%',right: '5%'}}>
                    <div 
                        onClick={()=>console.log('contact_header')}
                        className="w-100"
                        style={{fontSize: '16px',lineHeight: '20px',height: '20px', cursor:'pointer'}}>
                        <img className="blink" src={require('../assets/img/oval.svg')} alt="oval" style={{marginTop: '-2px', marginRight: '.5rem'}} />
                        Kontakt
                    </div>
                </div> */}
        <div
          style={{
            width: '100vw',
            minHeight: '100vh',
            backgroundColor: 'rgba(54,51,51,0.32)',
          }}
        >
          {content === 'projectsSuccess' ? <ProjectsFormSuccess /> : ''}
          {content === 'menu' ? <Menu /> : ''}
          {content === 'projects' ? <ProjectsForm /> : ''}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
  content: state.blur.content,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(BlurPage);
