export const ulStyle = {
  display: 'flex',
  justifyContent: 'center',
  margin: 0,
  padding: '1rem 0',
  listStyleType: 'none',
};

export const liStyle = {
  margin: '0 0.25rem',
};

export const buttonStyle = {
  display: 'block',
  width: '.75rem',
  height: '.75rem',
  padding: 0,
  border: 'none',
  borderRadius: '100%',
  backgroundColor: '#89CAD9',
  textIndent: '-9999px',
  outline: 'none',
};
export const buttonStyleActive = {
  display: 'block',
  width: '.75rem',
  height: '.75rem',
  padding: 0,
  border: 'none',
  borderRadius: '100%',
  backgroundColor: '#f2f2f2',
  textIndent: '-9999px',
  outline: 'none',
};
export const buttonStyleActiveGreen = {
  display: 'block',
  width: '.75rem',
  height: '.75rem',
  padding: 0,
  border: 'none',
  borderRadius: '100%',
  backgroundColor: '#084A59',
  textIndent: '-9999px',
  outline: 'none',
};
