import React from "react";
import { connect } from "react-redux";
import { toggleDisplayBlur } from "../../actions/blur";
import CastingHeader from "./CastingHeader";
import Carousel from "../../components/Carousel/Carousel";
import Gallery from "../../components/Gallery/Gallery";
import Contact from "../../components/Contact/Contact";
import Form from "../../components/Form/Form";
import Movie from "../About/Movie";
import WhoWeAre from "./WhoWeAre";
import IframeResizer from "iframe-resizer-react";

// const scrollToElement = (el) => {
//     let element = document.getElementById(el);
//     window.scrollTo(0, element.offsetTop)
// }

const Casting = ({ displayBlur, toggleDisplayBlur }) => {
  return (
    <>
      <CastingHeader />
      {/* <Form /> */}
      <section
        className="about bg-white"
        style={{
          minHeight: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="container-fluid h-100">
          <div className="d-flex flex-column h-100 text-center align-items-center pt-5 pb-5">
            <div style={{ minHeight: "20%" }}></div>
            <div
              id="info1"
              className="d-flex flex-column align-items-center w-100 text-white text-center trans-appear"
            >
              <div className="about__header lh-xs" style={{ color: "#084A59" }}>
                CHĘTNIE ZOBACZYMY
                <br />
                CIĘ NA EKRANIE
              </div>
              <div className="about__small" style={{ color: "#084A59" }}>
                Chcesz zagrać w filmie lub reklamie? Marzysz o zostaniu
                bohaterem programu telewizyjnego? Chcesz wziąć udział w ciekawym
                wydarzeniu? JustCast to najproszty sposób aby przekonać się czy
                kamera cię kocha!
              </div>
              {/* <div className="about__more">
                <div
                  className="about__more-text tk-nimbus-sans-extended"
                  style={{
                    color: "#084A59",
                    minWidth: "265px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                  // onClick={() => toggleDisplayBlur(displayBlur, 'projects')}
                >
                  <a
                    href={"https://justcast.pl/#/form"}
                    target={"_blanc"}
                    style={{ color: "rgb(8, 74, 89)", textDecoration: "none" }}
                  >
                    Dołącz do bazy statystów
                  </a>
                </div>
                <div
                  className="about__more-line"
                  style={{ width: "auto", borderTop: "3px solid #084A59" }}
                ></div>
              </div> */}
            </div>
            {/* <div className="w-100 text-right pt-4">
                            <div className="w-100 d-flex align-items-end" >
                                <div 
                                    onClick={()=>scrollToElement('contact_header')}
                                    className="w-100"
                                    style={{fontSize: '16px',lineHeight: '20px',height: '20px', cursor:'pointer'}}>
                                    <img className="blink" src={require('../../assets/img/oval.svg')} alt="oval" style={{marginTop: '-2px', marginRight: '.5rem'}} />
                                    Kontakt
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
      <Carousel page="casting" />
      <section
        className="bg-white"
        style={{
          minHeight: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="container-fluid h-100">
          {/* <WhoWeAre /> */}
          <div className="d-flex flex-column h-100 text-center align-items-center pt-5 pb-5">
            <IframeResizer
              src="https://castingi.gplusproduction.pl"
              heightCalculationMethod="lowestElement"
              className="castings"
            />
          </div>
        </div>
      </section>
      {/* <section
        style={{
          backgroundColor: "#F13067",
          maxWidth: "100%",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <div className="casting-line">
          CASTING! CASTING! CASTING! CASTING! CASTING! CASTING! CASTING!
          CASTING! CASTING! CASTING! CASTING! CASTING!
        </div>
        <div
          style={{
            textAlign: "center",
            paddingTop: "4.5rem",
            paddingBottom: "2rem",
          }}
        >
          <span
            style={{
              borderBottom: "2px solid #f2f2f2",
              paddingBottom: ".45rem",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            // onClick={() => toggleDisplayBlur(displayBlur, 'projects')}
          >
            <a
              href={"https://justcast.pl/#/form"}
              target={"_blanc"}
              style={{ color: "white", textDecoration: "none" }}
            >
              Zgłoś się na casting
            </a>
          </span>
        </div>
      </section> */}
      <Movie />
      <Gallery
        component="gallery"
        page="casting"
        index={4}
        title="nasze projekty"
        footerType={2}
        color="white"
      />
      <Contact />
    </>
  );
};

const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(Casting);
