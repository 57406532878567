import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toggleDisplayBlur } from '../../actions/blur';

const HeaderIcons = ({ type, leftIcon, displayBlur, toggleDisplayBlur }) => {
  const location = useLocation();
  let renderIconGPP = location.pathname !== '/casting';
  if (type === 2) {
    return (
      <div
        className="w-100 h-100 text-white text-right pt-5"
        style={{ position: 'relative' }}
      >
        <div className="h-50 align-items-start">
          <img
            src={require('../../assets/img/plus.svg')}
            alt="plus"
            onClick={() => toggleDisplayBlur(displayBlur, 'menu')}
            className="plusIcon"
          />
          {renderIconGPP ? (
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img
                id="gppIcon"
                src={require('../../assets/img/logo.svg')}
                alt="logo"
                style={{ position: 'fixed', display: 'none' }}
              />
            </Link>
          ) : (
            ''
          )}
        </div>
        <div
          //onClick={()=>scrollToElement('contact_header')}
          onClick={() =>
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth',
            })
          }
          id="contactIcon"
          className="contact-mini"
        >
          <img
            className="blink"
            src={require('../../assets/img/oval.svg')}
            alt="oval"
            style={{ marginTop: '-2px', marginRight: '.5rem' }}
          />
          Kontakt
        </div>
        {/* <div className="h-50 pb-5 d-flex align-items-end" >
                <FooterContact />
            </div> */}
      </div>
    );
  }
  if (type === 3) {
    return (
      <>
        <img
          src={require('../../assets/img/plus.svg')}
          alt="plus"
          onClick={() => toggleDisplayBlur(displayBlur, 'menu')}
          className="plusIcon"
          style={{ top: '20px' }}
        />
        {renderIconGPP ? (
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img
              id="gppIcon"
              src={require('../../assets/img/logo.svg')}
              alt="logo"
              style={{ position: 'fixed', display: 'none', top: '20px' }}
            />
          </Link>
        ) : (
          ''
        )}
      </>
    );
  }
  return (
    <div
      className="d-flex w-100 text-center"
      style={{ height: '20%', alignItems: 'flex-start' }}
    >
      <div className="w-100 text-white text-left">
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          <img src={require('../../assets/img/logo.svg')} alt="logo" />
        </Link>
      </div>
      <div className="w-100 text-white text-right">
        <img
          src={require('../../assets/img/plus.svg')}
          alt="menu"
          onClick={() => toggleDisplayBlur(displayBlur, 'menu')}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(HeaderIcons);
