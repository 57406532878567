import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toggleDisplayBlur } from '../../actions/blur';
import { connect } from 'react-redux';

const previewURL = require('../../assets/video/SR_10_sek.mp4');
const fullMovieURL = require('../../assets/video/SR_V4.mp4');

const Header = ({ displayBlur, toggleDisplayBlur }) => {
  const [movieState, setMovieState] = useState(false);
  const [videoURL, setVideoURL] = useState(previewURL);
  useEffect(() => {
    setVideoURL(videoURL);
    if (movieState) {
      let el = document.getElementById('headerFILM');
      el.pause();
      el.setAttribute('src', fullMovieURL);
      el.classList.add('full-width');
      el.load();
      el.play();
    }
  }, [movieState]);
  return (
    <header className="header" style={{ width: '100vw', height: '100vh' }}>
      <div className="header__overlay"></div>

      <video
        id="headerFILM"
        playsInline="playsInline"
        autoPlay="autoplay"
        loop="loop"
      >
        <source src={videoURL} type="video/mp4" />
      </video>

      <div
        className="container h-100 paddd"
        style={{ width: '100vw', maxWidth: '100vw' }}
      >
        <div className="d-flex h-100 text-center align-items-center">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img
              id="gppIcon"
              src={require('../../assets/img/logo.svg')}
              alt="logo"
              style={{ position: 'fixed', display: 'none', top: '20px' }}
            />
          </Link>
          <img
            src={require('../../assets/img/plus.svg')}
            alt="plus"
            onClick={() => toggleDisplayBlur(displayBlur, 'menu')}
            className="plusIcon"
            style={{ top: '20px' }}
          />
          {/* 
                <Link to="/" onClick={()=>window.scrollTo(0,0)}>
                    <img id="gppIcon" src={require('../../assets/img/logo.svg')} alt="logo" style={{position: 'fixed', display: 'none'}} />
                </Link> */}
          <div className="w-100 h-100 text-center header__subject">
            <div className="pb-5 d-none d-md-block text-center">
              <img
                src={require('../../assets/img/subject.svg')}
                alt="logo"
                className="pb-4 headerImg"
              />
              <div className="w-100 d-none d-md-flex text-center">
                <div
                  className="header__subject-item"
                  style={{ paddingRight: '.75rem' }}
                >
                  TELEWIZJA
                </div>
                <div
                  className="header__subject-item"
                  style={{ padding: '0 .75rem' }}
                >
                  FILM
                </div>
                <div
                  className="header__subject-item"
                  style={{ paddingLeft: '.75rem' }}
                >
                  REKLAMA
                </div>
              </div>
            </div>
            <div className="pb-5 d-block d-md-none text-center">
              <img
                src={require('../../assets/img/subject.svg')}
                alt="logo"
                className="pb-4 headerImg"
              />
              <div className="w-100 d-flex d-md-none text-center">
                <div
                  className="header__subject-item"
                  style={{ fontSize: '14px', paddingRight: '.75rem' }}
                >
                  REKLAMA
                </div>
                <div
                  className="header__subject-item"
                  style={{ fontSize: '14px', paddingLeft: '.75rem' }}
                >
                  PRODUKCJA FILMOWA
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-100 text-white text-center"
            style={{ position: 'absolute' }}
          >
            {movieState ? (
              ''
            ) : (
              <img
                onClick={() => setMovieState(true)}
                src={require('../../assets/img/play.svg')}
                className="pointer playIcon"
                alt="play"
              />
            )}
          </div>
          <div
            //onClick={()=>scrollToElement('contact_header')}
            onClick={() =>
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
              })
            }
            id="contactIcon"
            className="contact-mini"
          >
            <img
              className="blink"
              src={require('../../assets/img/oval.svg')}
              alt="oval"
              style={{ marginTop: '-2px', marginRight: '.5rem' }}
            />
            Kontakt
          </div>
          {/* <HeaderIcons type={2} /> */}
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(Header);
