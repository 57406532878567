import React from "react";
import Header from "../../components/Headers/Header";
import Gallery from "../../components/Gallery/Gallery";
import Carousel from "../../components/Carousel/Carousel";
import AboutUS from "../../components/AbouUs";
import Contact from "../../components/Contact/Contact";
import Form from "../../components/Form/Form";

function elementInViewport(el) {
  var ret = false;
  if (el) {
    if (
      window.pageYOffset + window.innerHeight + 50 > el.offsetTop &&
      window.pageYOffset < el.offsetTop + el.offsetHeight - 50
    ) {
      ret = true;
    }
  }

  return ret;
}
function setClassIfInViewport(el, className) {
  if (el) {
    if (elementInViewport(el) && !el.classList.contains(className)) {
      el.classList.add(className);
    }
    if (!elementInViewport(el) && el.classList.contains(className)) {
      el.classList.remove(className);
    }
  }
}
window.onscroll = function () {
  setClassIfInViewport(document.getElementById("info1"), "trans-appear-end");
  setClassIfInViewport(
    document.getElementById("gallerybox_2"),
    "trans-appear-end"
  );
  setClassIfInViewport(
    document.getElementById("gallerybox_4"),
    "trans-appear-end"
  );
  setClassIfInViewport(document.getElementById("whoweare"), "trans-appear-end");
  trackScrolling();
};
const exampleGallery = [
  {
    img: "",
    title: "",
    description: "",
  },
  {
    img: "",
    title: "",
    description: "",
  },
  {
    img: "",
    title: "",
    description: "",
  },
];
const images = ["", "", ""];

const isBottom = (el) => {
  if (el === null) return null;
  return el.getBoundingClientRect().bottom < 0;
};

const isEnter = (el) => {
  if (el === null) return null;
  let pos = el.getBoundingClientRect();
  return pos.height <= pos.top;
};

const trackScrolling = () => {
  if (document.getElementById("gppIcon")) {
    const wrappedElement = document.getElementsByTagName("header")[0];
    if (isBottom(wrappedElement)) {
      document.getElementById("gppIcon").style.display = "";
      document.removeEventListener("scroll", trackScrolling);
    } else {
      document.getElementById("gppIcon").style.display = "none";
    }
  }
  if (document.getElementById("contactIcon")) {
    const wrappedElement = document.getElementsByTagName("footer")[0];
    if (isEnter(wrappedElement)) {
      document.getElementById("contactIcon").style.display = "";
      document.removeEventListener("scroll", trackScrolling);
    } else {
      document.getElementById("contactIcon").style.display = "none";
    }
  }
  let whiteBG = document.getElementsByClassName("bg-white");
  let enter = false;
  for (let el of whiteBG) {
    let pos = el.getBoundingClientRect();
    if (pos.top > 0 && pos.height >= pos.top) enter = true;
  }
  if (document.getElementById("contactIcon"))
    document.getElementById("contactIcon").style.color = enter ? "#084A59" : "";
};

const Home = () => {
  return (
    <>
      <Header />
      {/* <Form /> */}
      <Gallery
        component="gallery1"
        page="index"
        index={4}
        title="nasze projekty"
        footerType={2}
        list={exampleGallery}
        color="green"
      />
      <Carousel page="index" images={images} />
      <AboutUS
        showSlider={false}
        title="JESTEŚMY NA TWOIM EKRANIE"
        description="Kreatywność i lata pracy w produkcji filmowej i telewizyjnej są mieszanką, dzięki której pokazujemy prawdziwe emocje w naszych programach. Potrafimy znaleźć drogę do poruszenia każdego grona odbiorców."
      />
      <Gallery
        component="gallery"
        page="index"
        index={2}
        title="co nowego"
        footer={1}
        list={exampleGallery}
        color="white"
        isFb={true}
      />
      <Contact />
    </>
  );
};
export default Home;
