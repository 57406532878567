import React from "react";
import HeaderIcons from "./HeaderIcons";

const HeaderImage = () => {
  return (
    <header className="header" style={{ width: "100vw", height: "100vh" }}>
      <div
        className="header__overlay"
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/img/6EE8A2AA3C11445DB6016328A843A63D.png") +
            ")",
          width: "100vw",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="container h-100 casting__container">
        <div className="d-flex h-100 text-center align-items-center">
          <div className="w-100 h-100 text-center header__subject">
            <HeaderIcons type={3} />
            <div className="pb-5">
              <img
                src={require("../../assets/img/subject.svg")}
                alt="subject"
                className="pb-4 headerImg"
              />
              <div className="w-100 d-flex text-left">
                <div className="flex-fill header__subject-item">ABOUT US</div>
              </div>
              <div
                //onClick={()=>scrollToElement('contact_header')}
                id="contactIcon"
                onClick={() =>
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: "smooth",
                  })
                }
                className="contact-mini"
              >
                <img
                  className="blink"
                  src={require("../../assets/img/oval.svg")}
                  alt="oval"
                  style={{ marginTop: "-2px", marginRight: ".5rem" }}
                />
                Kontakt
              </div>
            </div>
          </div>
          {/* <HeaderIcons type={2} /> */}
        </div>
      </div>
    </header>
  );
};

export default HeaderImage;
