import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toggleDisplayBlur } from '../../actions/blur';

const activeLang = {
  color: '#F64376',
  opacity: 1,
  cursor: 'pointer',
};
const defaultLang = {
  color: '#f2f2f2',
  opacity: '0.5',
  cursor: 'pointer',
};

const Menu = ({ toggleDisplayBlur, displayBlur }) => {
  const [lang, setLang] = useState('pl');

  const setPage = (page) => {
    toggleDisplayBlur(displayBlur);
    window.location.href =
      window.location.origin + window.location.pathname + '#/' + page;
    window.scrollTo(0, 0);
  };

  const toContact = () => {
    toggleDisplayBlur(displayBlur);
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return (
    <>
      <div className="blurPage__menu">
        <div style={{ textAlign: 'left' }}>
          <div
            className="blurPage__menu--item"
            style={{ cursor: 'pointer' }}
            onClick={() => setPage('')}
          >
            home
          </div>
          <div
            className="blurPage__menu--item"
            style={{ cursor: 'pointer' }}
            onClick={() => setPage('onas')}
          >
            about us
          </div>
          <div
            className="blurPage__menu--item"
            style={{ cursor: 'pointer' }}
            onClick={() => setPage('casting')}
          >
            casting
          </div>
          <div
            className="blurPage__menu--item"
            style={{ cursor: 'pointer' }}
            onClick={() => toContact()}
          >
            contact
          </div>
        </div>
        <div
          className="d-none d-sm-none d-md-block"
          style={{ paddingTop: '25%' }}
        >
          <img
            src={require('../../assets/img/just-cast-circle.svg')}
            alt="logo"
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <div className="blurPage__menuBottom">
        <div
          style={{
            display: 'flex',
            fontSize: '20px',
            letterSpacing: '0.43px',
            lineHeight: '93px',
            marginBottom: '10px',
          }}
        >
          <div
            style={lang === 'en' ? { ...activeLang } : { ...defaultLang }}
            onClick={() => setLang('en')}
          >
            En
          </div>
          <div
            style={
              lang === 'pl'
                ? { ...activeLang, marginLeft: '34px' }
                : { ...defaultLang, marginLeft: '34px' }
            }
            onClick={() => setLang('pl')}
          >
            Pl
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            fontSize: '18px',
            letterSpacing: '0',
            lineHeight: '21px',
          }}
        >
          <div
            style={{
              borderBottom: '3px solid #f2f2f2',
              paddingBottom: '.3rem',
            }}
          >
            <a
              href="https://www.facebook.com/GPlusProduction/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#fff', textDecoration: 'none' }}
            >
              #gpfacebook
            </a>
          </div>
          <div
            style={{
              marginLeft: '3.5rem',
              borderBottom: '3px solid #f2f2f2',
              paddingBottom: '.3rem',
            }}
          >
            <a
              href="https://instagram.com/gplus_production?igshid=c0r4tdga2xph"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#fff', textDecoration: 'none' }}
            >
              #gpinstagram
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(Menu);

//backgroundImage: 'url('+require('../../assets/img/justcastprojekty3.png')+')'
