import React, { useEffect } from "react";
import Slider from "./Slider/Slider";
import { Link } from "react-router-dom";

const AboutUS = ({ title, description, showSlider, colorWhite, scroll }) => {
  useEffect(() => {
    const id = window.location.href.split("#").slice(-1)[0];
    if (id && scroll) {
      const div = document.getElementById(id);
      if (div) {
        div.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <section
      id="cele"
      className="about"
      style={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className="container-fluid h-100">
        <div className="d-flex flex-column h-100 text-center align-items-center pt-5 pb-5">
          <div
            id="info1"
            className="d-flex flex-column align-items-center w-100 text-white text-center trans-appear"
            style={{ justifyContent: "center" }}
          >
            {showSlider ? (
              <Slider page="onas" colorWhite={true} />
            ) : (
              <>
                <div className="about__header">{title}</div>
                <div className="about__small">{description}</div>
                <div className="about__more d-none d-md-block">
                  <div className="about__more-text tk-nimbus-sans-extended">
                    <Link
                      to="/onas#cele"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Więcej o nas
                    </Link>
                  </div>
                  <div className="about__more-line"></div>
                </div>
              </>
            )}
          </div>
          {/* <div className="w-100 text-white text-right pt-4">
                        <div className="w-100 h-100 d-flex align-items-end" >
                            <FooterContact />
                        </div>
                    </div> */}
        </div>
      </div>
    </section>
  );
};
export default AboutUS;
