import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "./Footer";

const Contact = () => {
  const [displayForm, setDisplayForm] = useState(true);
  useEffect(() => {
    let innerWidth = window.innerWidth;
    if (innerWidth < 992) setDisplayForm(false);
  }, []);
  const [alert, setAlert] = useState({
    display: false,
    message: "",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const submitForm = async (e) => {
    e.preventDefault();
    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.email === "" ||
      formData.message === ""
    ) {
      return;
    }
    let alertMessage = "";
    let response = await axios.post(
      "https://gplusproduction.pl/services/cms_contact.php",
      formData
    );
    if (response.status === 200) {
      alertMessage = "Wiadmość została wysłana.";
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
    } else {
      alertMessage = "Bład! NIe udało się wysłać wiadomości!";
    }
    setAlert({
      display: true,
      message: alertMessage,
    });
    setTimeout(() => {
      setAlert({
        display: false,
        message: "",
      });
    }, 3500);
  };
  return (
    <footer
      className="contact contact-padding"
      style={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div className="container-fluid h-100" style={{ minHeight: "100vh" }}>
        <div
          className="d-flex flex-column h-100 text-center align-items-center"
          style={{
            minHeight: "100vh",
            paddingBottom: "9rem",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div style={{ width: "100%" }}>
              <div
                id="contact_header"
                className="contact__header d-flex w-100 pt-4"
              >
                <div
                  className="contact__header-title mr-auto"
                  style={{ padding: "5% 0" }}
                >
                  KONTAKT
                </div>
              </div>
            </div>
            {alert.display ? (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    padding: "1rem",
                    background: "#084A59",
                    width: "25rem",
                    color: "white",
                    marginTop: "1.5rem",
                    textAlign: "left",
                  }}
                >
                  {alert.message}
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              id="contact_form"
              className=" w-100 mt-4 "
              style={{ opacity: 1 }}
            >
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12 col-md-6">
                  <div className="d-flex d-md-none pb-5 sbmBtn">
                    <button
                      type="submit"
                      className="btn gp-btn-pink mr-auto"
                      onClick={() => setDisplayForm(true)}
                      style={{ display: displayForm ? "none" : "block" }}
                    >
                      Napisz do nas
                    </button>
                  </div>
                  <form
                    onSubmit={(e) => submitForm(e)}
                    style={{ display: displayForm ? "block" : "none" }}
                  >
                    <div className="form-group">
                      <input
                        required
                        className="inpt"
                        type="text"
                        placeholder="Imię"
                        value={formData.firstName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        required
                        className="inpt"
                        type="text"
                        placeholder="Nazwisko"
                        value={formData.lastName}
                        onChange={(e) =>
                          setFormData({ ...formData, lastName: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        required
                        className="inpt"
                        type="email"
                        placeholder="Adres e-mail"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        required
                        className="inpt"
                        placeholder="Twoja wiadomość"
                        rows="3"
                        value={formData.message}
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                      ></textarea>
                    </div>
                    <div className="d-flex pb-5 sbmBtn">
                      <button type="submit" className="btn gp-btn-pink ml-auto">
                        Wyślij
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12 col-md-6 pb-3">
                      <div className="contact__desc-header">GPLUS</div>
                      <div>
                        <div style={{ color: "#BDB9B9" }} className="pt-3">
                          Przemysłowa 12
                        </div>
                        <div style={{ color: "#BDB9B9" }}>30-222 Kraków</div>
                        {/* <div style={{color: '#BDB9B9'}} className="pt-3">
                                            Płk. Stanisława Dąbka 2 
                                        </div>
                                        <div style={{color: '#BDB9B9'}}>
                                            | 30-732 Kraków
                                        </div> */}
                        <div className="pt-3">kontakt@gplusproduction.pl</div>
                        <div style={{ color: "#BDB9B9" }} className="pt-3">
                          tel. +48 502 555 413
                        </div>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          paddingTop: "2.5rem",
                          paddingBottom: "1.5rem",
                        }}
                      >
                        <div
                          style={{
                            marginRight: "1.5rem",
                            borderRadius: "50%",
                            background: "#fff",
                          }}
                        >
                          <a
                            href="https://www.facebook.com/GPlusProduction/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={require("../../assets/img/fb.svg")}
                              alt="fbIcon"
                            />
                          </a>
                        </div>
                        <div style={{ position: "relative" }}>
                          <a
                            href="https://instagram.com/gplus_production?igshid=c0r4tdga2xph"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={require("../../assets/img/ig.svg")}
                              alt="igIcon"
                            />
                          </a>
                          <div
                            style={{
                              zIndex: 1,
                              position: "absolute",
                              top: "4px",
                              left: "4px",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              background: "#fff",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="contact__desc-header">JUST CAST</div>
                      <div>
                        <div style={{ color: "#BDB9B9" }} className="pt-3">
                          Przemysłowa 12
                        </div>
                        <div style={{ color: "#BDB9B9" }}>30-222 Kraków</div>
                        <div className="pt-3">kontakt@justcast.pl</div>
                        <div className="pt-3"></div>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          paddingTop: "2.5rem",
                          paddingBottom: "1.5rem",
                        }}
                      >
                        <div
                          style={{
                            marginRight: "1.5rem",
                            borderRadius: "50%",
                            background: "#fff",
                          }}
                        >
                          <a
                            href="https://www.facebook.com/Just-Cast-260705245843281"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={require("../../assets/img/fb.svg")}
                              alt="fbIcon"
                            />
                          </a>
                        </div>
                        <div style={{ position: "relative" }}>
                          <a
                            href="https://www.instagram.com/justcastkrk/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={require("../../assets/img/ig.svg")}
                              alt="igIcon"
                            />
                          </a>
                          <div
                            style={{
                              zIndex: 1,
                              position: "absolute",
                              top: "4px",
                              left: "4px",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              background: "#fff",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </footer>
  );
};

export default Contact;
