import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { toggleDisplayBlur, blurContent } from '../../actions/blur';

const ProjectsForm = ({ displayBlur, toggleDisplayBlur, blurContent }) => {
  const [alert, setAlert] = useState({
    display: false,
    message: '',
  });
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
    Comments: '',
    agree1: false,
    agree2: false,
    agree3: false,
  });
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [overlay, setOverlay] = useState(0);

  const handleUpload = (event) => {
    const fileList = Array.from(event.target.files);
    setFiles([...files, ...fileList]);
  };

  const removeFile = (index) => {
    let arr = [...files];
    arr.splice(index, 1);
    setFiles(arr);
  };

  useEffect(() => {
    let saveINDEX = 0;
    const mappedFiles = files.map((file) => ({
      ...file,
      preview: URL.createObjectURL(file),
      extension: file.type,
      base64: '',
      fileName: makeid(15) + '.' + file.type.split('/').pop(),
    }));
    files.map((file, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        mappedFiles[index].base64 = reader.result;
        saveINDEX++;
        if (saveINDEX === files.length) setPreviews(mappedFiles);
      };
    });
  }, [files]);

  const makeid = (length) => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (!formData.agree1 || !formData.agree2 || !formData.agree3) {
      setAlert({
        display: true,
        message: 'Wszystkie zgody są wymagane.',
      });
      setTimeout(() => {
        setAlert({
          display: false,
          message: '',
        });
      }, 3500);

      return;
    }

    let dataToSend = formData;
    formData.files = previews;
    let postURL =
      'https://gplusproduction.pl/services/cms_projects_contact.php';
    //let postURL = 'https://aden.pl/projects/just-cast/dev/API/Persons/saveApplicationForm';
    let response = await axios.post(postURL, dataToSend);
    // if(response.status === 200){
    //     let PersonsID = response.data.body.PersonsID;
    //     blurContent('projectsSuccess')
    // }
  };

  return (
    <div
      className="casting__container"
      style={{ paddingTop: '4rem', paddingBottom: '4rem' }}
    >
      <div>
        <div
          className="about__header"
          style={{ color: '#89CAD9', textAlign: 'left', maxWidth: '100%' }}
        >
          WEŹ UDZIAŁ W NASZYCH PROJEKTACH
        </div>
        <div
          className="about__small"
          style={{
            color: '#89CAD9',
            textAlign: 'left',
            maxWidth: '100%',
            marginTop: 0,
          }}
        >
          Wykorzystaj swoją szanę, poznajmy się!
        </div>
      </div>
      <form onSubmit={(e) => submitForm(e)}>
        <div
          className="row"
          style={{
            marginTop: '48px',
          }}
        >
          <div className="col-md-5" style={{ textAlign: 'left' }}>
            <div className="form-group">
              <input
                required
                className="inpt"
                type="text"
                placeholder="Imię*"
                value={formData.FirstName}
                onChange={(e) =>
                  setFormData({ ...formData, FirstName: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <input
                required
                className="inpt"
                type="text"
                placeholder="Nazwisko*"
                value={formData.LastName}
                onChange={(e) =>
                  setFormData({ ...formData, LastName: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <input
                required
                className="inpt"
                type="text"
                placeholder="Numer telefonu*"
                value={formData.Phone}
                onChange={(e) =>
                  setFormData({ ...formData, Phone: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <input
                required
                className="inpt"
                type="Email"
                placeholder="Adres e-mail*"
                value={formData.Email}
                onChange={(e) =>
                  setFormData({ ...formData, Email: e.target.value })
                }
              />
            </div>
            <div
              style={{
                fontSize: '12px',
                letterSpacing: 0,
                lineHeight: '12px',
                color: '#f2f2f2',
                marginBottom: '1rem',
              }}
            >
              {/* <div style={{width: '70%', textAlign: 'left'}}> */}
              *pole obowiązkowe
              {/* </div> */}
            </div>
          </div>
          <div className="col-md-7" style={{ textAlign: 'left' }}>
            <div className="form-group">
              <textarea
                className="inpt"
                placeholder="Twoja wiadomość"
                rows="6"
                value={formData.Comments}
                onChange={(e) =>
                  setFormData({ ...formData, Comments: e.target.value })
                }
              ></textarea>
            </div>
            <div className="form-group" style={{ display: 'flex' }}>
              <div
                style={{
                  position: 'relative',
                  height: '41px',
                  width: '189px',
                  border: '1px dashed #F64376',
                  textAlign: 'center',
                  lineHeight: '40px',
                  color: '#f2f2f2',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={require('../../assets/img/iconfinder_ic_attachment_48px_352034.svg')}
                  alt="attachment"
                  style={{
                    marginTop: '-.2rem',
                    marginRight: '1rem',
                    cursor: 'pointer',
                  }}
                />
                Dodaj załącznik
                <input
                  multiple={true}
                  onChange={(e) => handleUpload(e)}
                  type="file"
                  style={{
                    opacity: '0.0',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: '29.5px',
                  fontSize: '12px',
                  color: '#f2f2f2',
                }}
              >
                <div style={{ lineHeight: '14px' }}>
                  Max 5 zdjęć, filmów do 5MB
                </div>
                <div style={{ lineHeight: '14px', marginTop: '10px' }}>
                  JPG, PNG, PDF, MP4
                </div>
              </div>
            </div>
            {previews.map((file, index) => (
              <div
                key={index}
                style={{
                  width: '64px',
                  height: '48px',
                  marginRight: '.5rem',
                  position: 'relative',
                  display: 'inline-block',
                }}
                onMouseEnter={() => setOverlay(index)}
              >
                <img
                  src={file.preview}
                  alt="preview"
                  style={{ width: '100%' }}
                />
                {overlay === index ? (
                  <div
                    onMouseLeave={() => setOverlay(null)}
                    style={{
                      width: '100%',
                      height: '100%',
                      background: 'rgba(0,0,0,.5)',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                    onClick={() => removeFile(index)}
                  >
                    <div style={{ cursor: 'pointer', color: '#F64376' }}>
                      Usuń
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
          <div
            className="col-md-12"
            style={{ marginTop: '43px', textAlign: 'left' }}
          >
            <div>
              <div style={{ display: 'inline-flex' }}>
                <div
                  onClick={() =>
                    setFormData({ ...formData, agree1: !formData.agree1 })
                  }
                  style={{
                    boxSizing: 'border-box',
                    height: '21px',
                    width: '21px',
                    minWidth: '21px',
                    border: '1px solid #D1CACA',
                    cursor: 'pointer',
                  }}
                >
                  {formData.agree1 ? (
                    <img
                      src={require('../../assets/img/Path 8.svg')}
                      alt="checkbox"
                      style={{ marginTop: '-.5rem', marginLeft: '.3rem' }}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ marginLeft: '19.18px', color: '#f2f2f2' }}>
                  Zgadzam się z zasadami polityki prywatności zawartymi w
                  Regulaminie
                </div>
              </div>
            </div>
            <div style={{ marginTop: '21px' }}>
              <div style={{ display: 'inline-flex' }}>
                <div
                  onClick={() =>
                    setFormData({ ...formData, agree2: !formData.agree2 })
                  }
                  style={{
                    boxSizing: 'border-box',
                    height: '21px',
                    width: '21px',
                    minWidth: '21px',
                    border: '1px solid #D1CACA',
                    cursor: 'pointer',
                  }}
                >
                  {formData.agree2 ? (
                    <img
                      src={require('../../assets/img/Path 8.svg')}
                      alt="checkbox"
                      style={{ marginTop: '-.5rem', marginLeft: '.3rem' }}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ marginLeft: '19.18px', color: '#f2f2f2' }}>
                  Zgadzam się na przetwarzanie moich danych osobowych
                </div>
              </div>
            </div>
            <div style={{ marginTop: '21px' }}>
              <div style={{ display: 'inline-flex' }}>
                <div
                  onClick={() =>
                    setFormData({ ...formData, agree3: !formData.agree3 })
                  }
                  style={{
                    boxSizing: 'border-box',
                    height: '21px',
                    width: '21px',
                    minWidth: '21px',
                    border: '1px solid #D1CACA',
                    cursor: 'pointer',
                  }}
                >
                  {formData.agree3 ? (
                    <img
                      src={require('../../assets/img/Path 8.svg')}
                      alt="checkbox"
                      style={{ marginTop: '-.5rem', marginLeft: '.3rem' }}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ marginLeft: '19.18px', color: '#f2f2f2' }}>
                  Potwierdzam prawdziwość danych zawartych w formularzu
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-7" style={{ marginTop: '1rem' }}>
            <div
              style={{
                display: 'inline-flex',
                color: '#f2f2f2',
                width: '70%',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  fontSize: '16px',
                  padding: '13px 28px',
                  cursor: 'pointer',
                }}
              >
                Anuluj
              </div>
              <button
                type="submit"
                style={{
                  padding: '13px 28px',
                  cursor: 'pointer',
                  backgroundColor: '#F13067',
                  color: '#f2f2f2',
                  border: 'none',
                }}
              >
                Zgłoś się
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  // return(
  //     <>
  //         <div style={{
  //                 position: 'absolute',
  //                 top: '4%',
  //                 left: '10%',
  //                 color:'#89CAD9',
  //                 textAlign: 'left'}}>
  //         <div style={{
  //             position: 'relative'
  //         }}>
  //             <div style={{
  //                 fontSize: '80px',
  //                 lineHeight: '93px',
  //                 textTransform: 'uppercase',
  //                 letterSpacing: '1.7px'}}>
  //                 WEŹ UDZIAŁ W NASZYCH PROJEKTACH
  //             </div>
  //             <div style={{
  //                 marginTop: '29',
  //                 letterSpacing: 0,
  //                 lineHeight: '12px',
  //                 fontSize: '22px'}}>
  //                 Wykorzystaj swoją szanę, poznajmy się!
  //             </div>
  //             {
  //                 alert.display ?
  //                 <div style={{width: '100%'}}>
  //                     <div style={{padding: '1rem', background: '#084A59', width: '25rem', color: 'white', marginTop: '1.5rem', textAlign: 'left'}}>
  //                        {alert.message}
  //                     </div>
  //                 </div>
  //                 :''
  //             }
  // <form onSubmit={(e)=>submitForm(e)}>
  //     <div
  //         className="row"
  //         style={{
  //             marginTop: '48px',
  //         }}>
  //         <div className="col-md-5">
  //             <div className="form-group">
  //                 <input
  //                     required
  //                     className="inpt"
  //                     type="text"
  //                     placeholder="Imię*"
  //                     value={formData.FirstName}
  //                     onChange={(e)=>setFormData({...formData, FirstName:e.target.value})} />
  //             </div>
  //             <div className="form-group">
  //                 <input
  //                     required
  //                     className="inpt"
  //                     type="text"
  //                     placeholder="Nazwisko*"
  //                     value={formData.LastName}
  //                     onChange={(e)=>setFormData({...formData, LastName:e.target.value})} />
  //             </div>
  //             <div className="form-group">
  //                 <input
  //                     required
  //                     className="inpt"
  //                     type="text"
  //                     placeholder="Numer telefonu*"
  //                     value={formData.Phone}
  //                     onChange={(e)=>setFormData({...formData, Phone:e.target.value})} />
  //             </div>
  //             <div className="form-group">
  //                 <input
  //                     required
  //                     className="inpt"
  //                     type="Email"
  //                     placeholder="Adres e-mail*"
  //                     value={formData.Email}
  //                     onChange={(e)=>setFormData({...formData, Email:e.target.value})} />
  //             </div>
  //             <div style={{fontSize: '12px', letterSpacing: 0, lineHeight: '12px', color: '#f2f2f2'}}>
  //                 *pole obowiązkowe
  //             </div>
  //         </div>
  //         <div className="col-md-7">
  //             <div className="form-group">
  //                 <textarea
  //                     className="inpt"
  //                     placeholder="Twoja wiadomość"
  //                     rows="6"
  //                     value={formData.message}
  //                     onChange={(e)=>setFormData({...formData, message:e.target.value})}></textarea>
  //             </div>
  //             <div className="form-group" style={{display: "flex"}}>
  //                 <div style={{position: 'relative', height: '41px', width: '189px', border: '1px dashed #F64376', textAlign: "center", lineHeight: '40px', color: "#f2f2f2",cursor: 'pointer'}} >
  //                     <img
  //                         src={require('../../assets/img/iconfinder_ic_attachment_48px_352034.svg')}
  //                         alt="attachment"
  //                         style={{
  //                             marginTop: '-.2rem',
  //                             marginRight: '1rem',
  //                             cursor: 'pointer'
  //                         }} />
  //                     Dodaj załącznik
  //                     <input multiple={true} onChange={(e) => handleUpload(e)} type="file" style={{opacity: '0.0', position: 'absolute', top:0, left: 0, bottom: 0, right:0, width: '100%', height:'100%', cursor: 'pointer'}} />
  //                 </div>
  //                 <div style={{marginLeft: '29.5px', fontSize: '12px', color: '#f2f2f2'}}>
  //                     <div style={{lineHeight:'14px'}}>Max 5 zdjęć, filmów do 5MB</div>
  //                     <div style={{lineHeight:'14px', marginTop: '10px'}}>JPG, PNG, PDF, MP4</div>
  //                 </div>
  //             </div>
  //             {previews.map((file,index) => <div key={index} style={{width: '64px', height: '48px', marginRight: '.5rem', position: 'relative', display: 'inline-block'}} onMouseEnter={()=>setOverlay(index)}>
  //                 <img src={file.preview} alt='preview' style={{width: '100%'}}  />
  //                 {
  //                     overlay === index ? <div
  //                         onMouseLeave={()=>setOverlay(null)}
  //                         style={{
  //                             width: '100%',
  //                             height: '100%',
  //                             background:'rgba(0,0,0,.5)',
  //                             position:'absolute',
  //                             top:0,
  //                             left:0,
  //                             fontSize: '10px',
  //                             textAlign: 'center'
  //                         }}
  //                         onClick={()=>removeFile(index)}
  //                     >
  //                         <div style={{cursor: 'pointer', color: '#F64376'}}>
  //                             Usuń
  //                         </div>
  //                     </div> : ''
  //                 }
  //             </div>)}
  //         </div>
  //         <div className="col-md-12" style={{marginTop: '43px'}}>
  //             <div>
  //                 <div style={{display: 'inline-flex'}}>
  //                     <div
  //                         onClick={()=>setFormData({...formData, agree1: !formData.agree1})}
  //                         style={{
  //                             boxSizing: 'border-box',
  //                             height: '21px',
  //                             width: '21px',
  //                             minWidth: '21px',
  //                             border: '1px solid #D1CACA',
  //                             cursor: 'pointer'
  //                         }}>
  //                        {
  //                            formData.agree1 ?
  //                            <img
  //                            src={require('../../assets/img/Path 8.svg')}
  //                            alt="checkbox"
  //                            style={{marginTop: '-.5rem', marginLeft: '.3rem'}} />
  //                            :''
  //                         }
  //                     </div>
  //                     <div style={{marginLeft: '19.18px', color: '#f2f2f2'}}>Zgadzam się z zasadami polityki prywatności zawartymi w Regulaminie</div>
  //                 </div>
  //             </div>
  //             <div style={{marginTop: '21px'}}>
  //                 <div style={{display: 'inline-flex'}}>
  //                     <div
  //                         onClick={()=>setFormData({...formData, agree2: !formData.agree2})}
  //                         style={{
  //                             boxSizing: 'border-box',
  //                             height: '21px',
  //                             width: '21px',
  //                              minWidth: '21px',
  //                             border: '1px solid #D1CACA',
  //                             cursor: 'pointer'
  //                         }}>
  //                        {
  //                            formData.agree2 ?
  //                            <img
  //                            src={require('../../assets/img/Path 8.svg')}
  //                            alt="checkbox"
  //                            style={{marginTop: '-.5rem', marginLeft: '.3rem'}} />
  //                            :''
  //                         }
  //                     </div>
  //                     <div style={{marginLeft: '19.18px', color: '#f2f2f2'}}>Zgadzam się na przetwarzanie moich danych osobowych</div>
  //                 </div>
  //             </div>
  //             <div style={{marginTop: '21px'}}>
  //                 <div style={{display: 'inline-flex'}}>
  //                     <div
  //                         onClick={()=>setFormData({...formData, agree3: !formData.agree3})}
  //                         style={{
  //                             boxSizing: 'border-box',
  //                             height: '21px',
  //                             width: '21px',
  //                              minWidth: '21px',
  //                             border: '1px solid #D1CACA',
  //                             cursor: 'pointer'
  //                         }}>
  //                        {
  //                            formData.agree3 ?
  //                            <img
  //                            src={require('../../assets/img/Path 8.svg')}
  //                            alt="checkbox"
  //                            style={{marginTop: '-.5rem', marginLeft: '.3rem'}} />
  //                            :''
  //                         }
  //                     </div>
  //                     <div style={{marginLeft: '19.18px', color: '#f2f2f2'}}>Potwierdzam prawdziwość danych zawartych w formularzu</div>
  //                 </div>
  //             </div>
  //         </div>
  //         <div className="col-md-5"></div>
  //         <div className="col-md-7">
  //             <div style={{display: 'inline-flex', color: '#f2f2f2', width: '70%', justifyContent: 'flex-end'}}>
  //                 <div
  //                     style={{fontSize: '16px',  padding: '13px 28px', cursor: 'pointer'}}>
  //                     Anuluj
  //                 </div>
  //                 <button
  //                     type="submit"
  //                     style={{
  //                         padding: '13px 28px',
  //                         cursor: 'pointer',
  //                         backgroundColor: '#F13067',
  //                         color: '#f2f2f2',
  //                         border: 'none'
  //                     }}>
  //                     Zgłoś się</button>
  //             </div>
  //         </div>
  //     </div>
  // </form>
  //         </div>

  //         </div>
  //     </>
  // )
};
const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur, blurContent })(
  ProjectsForm
);
