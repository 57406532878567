import React from "react";
import { connect } from "react-redux";
import { toggleDisplayBlur } from "../../actions/blur";

const FooterCasting = ({ color, toggleDisplayBlur, displayBlur }) => {
  let fontColor = color === "green" ? "#f2f2f2" : "#084A59";
  return (
    <div className="w-100 pt-5 d-none d-md-flex">
      <div className="row w-100">
        <div className="col-4 offset-4 text-center gplusfacebook">
          <div className="d-flex justify-content-center w-100">
            <div
              style={{
                borderBottom: "3px solid",
                paddingBottom: "3px",
                cursor: "pointer",
                color: fontColor,
              }}
              // onClick={() => toggleDisplayBlur(displayBlur, 'projects')}
            >
              {/* <a
                href={'https://justcast.pl/#/form'}
                target={'_blanc'}
                style={{ color: 'white', textDecoration: 'none' }}
              >
              Zgłoś się na casting
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(FooterCasting);
