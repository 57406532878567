import React from 'react';
import { connect } from 'react-redux';
import { toggleDisplayBlur } from '../../actions/blur';

const ProjectsFormSuccess = ({ toggleDisplayBlur, displayBlur }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '20%',
        left: '10%',
        fontSize: '100px',
        textTransform: 'uppercase',
        letterSpacing: '2.13px',
        color: '#89CAD9',
        display: 'flex',
      }}
    >
      <div style={{ textAlign: 'left' }}>
        <div>Dziękujemy!</div>
        <div
          style={{
            color: '#F2F2F2',
            fontFamily: 'Nimbus Sans D OT',
            fontSize: '16px',
            letterSpacing: 0,
            lineHeight: '32px',
          }}
        >
          <div>
            Twoje dane zostały przesłane do pracownika Just Cast.
            <br />
            Zweryfikujemy Twoje zgłoszenie i skontaktujemy się z Tobą jak
            najszybciej.
          </div>
          <div style={{ display: 'inline-flex', marginTop: '2.5rem' }}>
            <div style={{ marginRight: '.5rem' }}>
              <img src={require('../../assets/img/star.svg')} alt="star" />
            </div>
            <div style={{ marginTop: '.3rem' }}>
              Kontaktujemy się tylko z wybranymi osobami.
            </div>
          </div>
          <div style={{ marginTop: '57px' }}>
            <div
              style={{
                height: '40px',
                width: '195px',
                textAlign: 'center',
                backgroundColor: '#F13067',
                fontSize: '16px',
                textTransform: 'none',
              }}
            >
              <div
                onClick={() => toggleDisplayBlur(displayBlur)}
                style={{ lineHeight: '40px', cursor: 'pointer' }}
              >
                Wróć na stronę
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  displayBlur: state.blur.displayBlur,
});

export default connect(mapStateToProps, { toggleDisplayBlur })(
  ProjectsFormSuccess
);
