import React from "react";
import { connect } from "react-redux";
import { toggleDisplayBlur } from "../../actions/blur";

const FormAgenda = ({ children, background }) => {
  return (
    <section
      className="castingForm"
      style={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="container-fluid h-100"
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <div
          className="d-flex"
          style={{
            marginTop: "150px",
            paddingRight: "20px",
            justifyContent: "center",
            fontFamily: "Anton, sans-serif",
            backgroundColor: "#F64376",
          }}
        >
          <div className="about__header">CASTING!</div>
        </div>
        <div
          className="d-flex flex-column align-items-center w-100 text-white text-center textType1"
          style={{
            justifyContent: "center",
            marginTop: "50px",
            backgroundColor: "rgba(100, 110, 200, 0.4)",
            fontSize: "22px",
            lineHeight: "2.5rem",
          }}
        >
          {children}
        </div>
        <div
          className="row w-100 text-white text-center"
          style={{
            fontWeight: "bold",
            margin: "50px 0",
            marginBottom: "50px",
            backgroundColor: "rgba(100, 110, 200, 0.9)",
          }}
        >
          {/* <div className="col-md-3 py-1">+48 517 339 407</div> */}
          <div className="col-md-4 py-1">kontakt@justcast.pl</div>
          <div className="col-md-4 py-1">
            <a
              href="https://www.facebook.com/Just-Cast-260705245843281"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              #justcastfacebook
            </a>
          </div>
          <div className="col-md-4 py-1">
            <a
              href="https://www.instagram.com/justcastkrk/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              #justcastinstagram
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({ displayBlur: state.blur.displayBlur });
export default connect(mapStateToProps, { toggleDisplayBlur })(FormAgenda);
