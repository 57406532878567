import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import Rules from './components/Rules/Rules.js';
import About from './views/About/About.js';
import Casting from './views/Casting/Casting.js';
import Index from './views/Index.js';

const Routes = (props) => {
  return (
    <Router>
      <Route path="/regulamin" component={Rules} />
      <Route path="/casting" component={Casting} />
      <Route path="/onas" component={About} />
      <Route exact path="/" component={Index} />
    </Router>
  );
};

export default Routes;
