import React from "react";

const GalleryItem = ({
  index,
  src,
  sColor,
  color,
  title,
  description,
  no,
  display,
  isFb,
  href,
}) => {
  const styleWithFb = {
    width: "100%",
    height: "80%",
  };
  const regularStyle = {
    width: "100%",
    height: "80%",
  };
  return (
    <div
      id={"info" + index + "-box" + no}
      className="col-lg-4 col-md-6 mb-5"
      style={{ display: display ? "none" : "block" }}
    >
      <a
        className="gallery__wrapper"
        href={href}
        rel="noopener"
        noreferrer="true"
        target="_blank"
      >
        <img
          src={require("../../assets/img/" + src)}
          alt="img"
          style={isFb ? styleWithFb : regularStyle}
        />
        <div className="gallery__item-info" style={{ color: sColor }}>
          <div className="gallery__item-info-header">{title}</div>
          <div className="gallery__item-info-desc">{description}</div>
        </div>
      </a>
    </div>
  );
};
export default GalleryItem;
