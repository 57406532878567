import { BLUR_DISPLAY, BLUR_CONTENT } from '../actions/types';

const initialState = {
  displayBlur: false,
  content: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BLUR_DISPLAY:
      return {
        ...state,
        displayBlur: payload,
      };
    case BLUR_CONTENT:
      return {
        ...state,
        content: payload,
      };
    default:
      return state;
  }
}
